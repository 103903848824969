* {
   box-sizing: border-box;
   font-family: 'Nunito Sans';
}

html,
body {
   font-family: 'Nunito Sans';
   font-size: 62.5%;
   font-weight: 400;
   -webkit-overflow-scrolling: touch;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
   margin: 0 auto;
   max-width: 400px;
}

html,
body,
main {
   height: 100%;
}

body {
   color: #222;
   font-size: 1.4rem;
   line-height: 1.25;
   box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
   margin: 0;
   padding: 0;
}

body,
caption,
th,
td,
input,
textarea,
select,
option,
legend,
fieldset,
h1,
h2,
h3,
h4,
h5,
h6 {
   font-size-adjust: 0.5;
}

.active {
   color: #0070dd !important;
}

input,
textarea {
   font-family: 'Roboto', sans-serif;
}

ul {
   list-style: none;
}

a {
   text-decoration: none;
   color: #222;
}

.opaque {
   opacity: 1 !important;
}

.cursor-pointer {
   cursor: pointer;
}

/* Scrollbar CSS */
::-webkit-scrollbar {
   width: 10px;
   background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
   box-shadow: inset 0 0 4px rgba(5, 5, 5, 0.4);
}

::-webkit-scrollbar-track:active {
   box-shadow: inset 0 0 4px rgba(5, 5, 5, 0.4);
}

::-webkit-scrollbar:active {
   width: 10px;
   background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
   background-color: rgba(185, 185, 185, 0.7);
   border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
   background-color: rgba(15, 8, 8, 0.3);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type='number'] {
   -moz-appearance: textfield;
}
